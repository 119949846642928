import React from 'react';
import Register from './components/Register';

function Registerpage() {
    return (
        <main className=''>
            <Register />
        </main>
    );
}

export default Registerpage;